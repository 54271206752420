import { Slot } from "@radix-ui/react-slot";
import type { VariantProps } from "cva";
import { forwardRef } from "react";

import { cva } from "#cva.config";

const buttonVariants = cva({
	base: "inline-flex items-center justify-center gap-2 rounded-lg font-medium text-sm ring-offset-background transition-colors disabled:pointer-events-none [&[href]]:cursor-pointer disabled:opacity-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
	variants: {
		$variant: {
			default:
				"border border-primary-500 bg-primary-500 text-white hover:border-primary-500/90 dark:bg-primary-600 dark:hover:bg-primary-600/90 hover:bg-primary-500/90",
			destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
			outline:
				"border border-input bg-background dark:border-zinc-700/50 dark:bg-transparent dark:hover:bg-zinc-700/30 hover:bg-accent dark:hover:text-zinc-300 dark:text-zinc-300 hover:text-accent-foreground",
			translucent:
				"border border-input bg-background text-gray-600 dark:border-zinc-700/60 dark:bg-[#202035]/50 dark:hover:bg-zinc-700/60 hover:bg-accent dark:hover:text-zinc-300 dark:text-zinc-300 hover:text-gray-800",
			secondary:
				"border border-secondary bg-secondary text-gray-600 dark:border-zinc-700/10 dark:bg-zinc-700/60 dark:hover:bg-zinc-700/80 hover:bg-secondary/80 dark:text-zinc-300",
			"secondary-opaque":
				"border border-slate-200 bg-white text-gray-600 dark:border-zinc-700 hover:border-slate-300/60 dark:bg-[#202035] dark:hover:bg-zinc-700 hover:bg-slate-100 dark:hover:text-zinc-300 dark:text-gray-300",
			"secondary-no-theming":
				"border border-slate-200 bg-white text-gray-600 dark:border-zinc-200 dark:hover:border-zinc-300/60 hover:border-slate-300/60 dark:hover:bg-zinc-50 hover:bg-slate-50",
			ghost: "bg-transparent dark:hover:bg-zinc-700/50 hover:bg-slate-100 hover:text-accent-foreground",
			link: "text-primary underline-offset-4 hover:underline",
			glass:
				"rounded-3xl border border-white/60 bg-transparent text-white shadow-lg drop-shadow-lg backdrop-blur-md duration-300 dark:border-white/60 dark:hover:border-white/90 hover:border-white/90 dark:bg-transparent dark:text-white",
			"glass-btn":
				"rounded-full border border-white/10 bg-white/20 text-white backdrop-blur-md dark:border-white/10 dark:bg-white/20 dark:text-white",
			"glass-btn-transparent":
				"!border-0 !bg-transparent !text-white rounded-full !dark:bg-transparent !dark:text-white",
			sponsor:
				"border-fuchsia-500 bg-gradient-to-r from-purple-600 to-fuchsia-500 text-white hover:border-fuchsia-500 hover:text-opacity-100 hover:opacity-90",
			none: "",
		},
		$size: {
			default: "h-10 px-4 py-2",
			sm: "h-9 rounded-md px-3",
			lg: "h-11 rounded-md px-8",
			icon: "size-10",
		},
	},
	defaultVariants: {
		$variant: "default",
		$size: "default",
	},
});

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, $variant, $size, asChild = false, type = "button", ...props }, ref) => {
		const Comp = asChild ? Slot : "button";
		return <Comp className={buttonVariants({ $variant, $size, className })} ref={ref} type={type} {...props} />;
	},
);
Button.displayName = "Button";

export { buttonVariants };
